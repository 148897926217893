<template>
    <div class="PlanCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" :inline="true" label-width="100px" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-select
                                placeholder="请选择"
                                v-model="form.deptCode"
                                filterable
                                @change="handleSelectDeptOrDate"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                v-model="form.date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                @change="handleSelectDeptOrDate"
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-select
                                property="请选择"
                                v-model="form.cashierCode"
                                filterable
                                @change="handleSelectStaff"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="item in meta.cashiers"
                                    :value="item.code"
                                    :key="item.code"
                                    :label="item.realName"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input placeholder="" readOnly="true" v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="购买笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.goodsCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购买人数">
                            <el-input placeholder="" readOnly="true" v-model="form.memberCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购买总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalMoney" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <ef-remark v-model="form.remark" />
                </el-row>
            </el-form>
        </el-card>
        <el-card
            shadow="never"
            style="margin-top: 8px; height: 350px; overflow: auto"
            align="left"
            v-if="tableData.length"
        >
            <el-row>
                <el-col :span="12" v-for="item in tableData" :key="item">
                    <el-table
                        ref="detailData"
                        border
                        stripe
                        class="selfTable"
                        style="width: 100%; margin-bottom: 15px"
                        :data="item.settlePayDoList"
                        size="mini"
                        :highlight-current-row="true"
                        min-height="400px"
                    >
                        <el-table-column prop="planName" label="计划名称" width="180" />
                        <el-table-column prop="templateName" label="支付类型" width="130" />
                        <el-table-column prop="goodsSumMoney" label="应收总额" width="100">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.goodsSumMoney | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="paid" label="支付总额" width="150">
                            <template slot-scope="scope">
                                <el-input-number
                                    align="left"
                                    size="small"
                                    type="number"
                                    :controls="false"
                                    v-model="scope.row.paid"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import Util from '../../../../js/Util';
import MoneyUtil from '../../../../js/MoneyUtils';
import EfRemark from 'components/EfRemark';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'PlanDailySettleCreate',
    components: { EfRemark },
    data() {
        return {
            form: {
                deptCode: '',
                date: this.formatDate(new Date()),
                goodsCount: '',
                memberCount: '',
                totalMoney: '',
                cashierName: '',
                cashierCode: '',
                creator: this.$store.state.session.name,
                sellId: [],
            },
            tableData: [],
            url: {
                handleSelectGoodsPlan: '/merchandising/settle/calc',
                queryCashier: '/merchandising/settle/cashier',
                batchSave: '/merchandising/settle/settle',
            },
            loading: false,
            meta: {
                depts: [],
                cashiers: [],
            },
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.meta.depts = rst;
        });
    },
    methods: {
        handleSave() {
            const _this = this;
            if (!_this.form.date) {
                _this.$message.error('请选择结算日期');
                return;
            }
            if (!_this.form.remark) {
                _this.$message.error('请填写备注');
                return;
            }
            if (!_this.$refs.detailData || _this.$refs.detailData.length <= 0) {
                _this.$message.error('当前时间没有需要结算的数据');
                return;
            }
            const pays = [];
            const bizCode = [];
            const templateCode = [];
            for (const re of _this.$refs.detailData) {
                for (const f of re.data) {
                    if (!f.paid) {
                        _this.$message.error(
                            '【' + f.planName + '】的' + '【' + f.templateName + '】的实收金额没有填写'
                        );
                        return;
                    }
                    const temMoney = MoneyUtil.moneyToDb(f.paid);
                    pays.push(temMoney);
                    bizCode.push(f.planCode);
                    templateCode.push(f.templateCode);
                }
            }
            const _params = {
                deptCode: _this.form.deptCode,
                settleDate: _this.form.date,
                cashier: _this.form.cashierCode,
                remark: _this.form.remark,
                totalMoney: MoneyUtil.moneyToDb(_this.form.totalMoney),
                goodsCount: _this.form.goodsCount,
                memberCount: _this.form.memberCount,
                pays: pays,
                bizCode: bizCode,
                templateCode: templateCode,
            };
            UrlUtils.PostRemote(this, this.url.batchSave, _params);
        },
        handleSelectDeptOrDate() {
            this.meta.cashiers = [];
            this.form.cashierCode = '';
            this.initData();
            this.queryStaff();
        },
        handleSelectStaff() {
            this.initData();
            this.handleQuery();
        },
        queryStaff() {
            const _this = this;
            if (_this.form.date && _this.form.deptCode) {
                const _staffParams = { params: { deptCode: _this.form.deptCode, date: _this.form.date } };
                Util.queryTable(_this, _this.url.queryCashier, _staffParams, (data) => {
                    _this.meta.cashiers = data.data;
                    if (data.data.length == 1) {
                        _this.form.cashierCode = data.data[0].code;
                    }
                    _this.handleQuery();
                });
            } else {
                _this.handleQuery();
            }
        },
        handleQuery() {
            const _this = this;
            if (_this.form.deptCode && _this.form.date) {
                const _params = {
                    params: {
                        date: _this.form.date,
                        deptCode: _this.form.deptCode,
                        cashier: _this.form.cashierCode,
                    },
                };
                Util.queryTable(_this, _this.url.handleSelectGoodsPlan, _params, (data) => {
                    _this.form.goodsCount = data.data.goodsCount;
                    _this.form.memberCount = data.data.memberCount;
                    _this.form.totalMoney = _this.fmtMoney(data.data.totalMoney);
                    _this.tableData = data.data.settlePoList;
                });
            } else {
                _this.$message.error('请选择门店和结算日期');
            }
        },
        initData() {
            this.form.goodsCount = '';
            this.form.memberCount = '';
            this.form.totalMoney = '';
            this.form.sellId = [];
            this.tableData = [];
        },
    },
};
</script>

<style scoped>
.selfTable .th {
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
}

.selfTable .td {
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
}
</style>
